import { debounce } from '../../Utility/utils';
import { Editor } from 'roosterjs';
import { PluginEventType, GetContentMode, Indentation, Alignment, FontSizeChange, ChangeSource, Keys, ClearFormatMode, QueryScope } from 'roosterjs-editor-types';
import {
    ImageEdit,
    TableResize,
    ContentEdit,
    HyperLink,
    Paste,
    CustomReplace,
    CutPasteListChain,
    TableCellSelection,
    resetImage,
} from 'roosterjs-editor-plugins';

import { readFile } from 'roosterjs-editor-dom';
import {
    getFormatState,
    clearFormat,
    toggleBold,
    toggleItalic,
    toggleUnderline,
    setBackgroundColor,
    setTextColor,
    toggleBullet,
    toggleNumbering,
    toggleStrikethrough,
    toggleBlockQuote,
    toggleSubscript,
    toggleSuperscript,
    createLink,
    removeLink,
    setImageAltText,
    toggleHeader,
    setFontName,
    setFontSize,
    changeFontSize,
    setIndentation,
    insertTable,
    editTable,
    setAlignment
} from "roosterjs-editor-api";

const eventTypesToRefreshFormatState = {
    [PluginEventType.KeyUp]: true,
    [PluginEventType.MouseDown]: true,
    [PluginEventType.MouseUp]: true,
    [PluginEventType.ContentChanged]: true,
    [PluginEventType.PendingFormatStateChanged]: true
};

const eventTypesToTriggerContentChange = {
    [PluginEventType.Input]: true,
    [PluginEventType.ContentChanged]: true
};

class InternalPlugin {
    constructor(editorWrapper) {
        this.editorWrapper = editorWrapper;
    }

    getName() {
        return 'InternalPlugin';
    }

    initialize(editor) {
        this.editor = editor;
    }

    dispose() {
        this.editor = null;
        this.editorWrapper = null;
    }

    onPluginEvent(event) {
        if (!this.editorWrapper) {
            return;
        }

        if (eventTypesToTriggerContentChange[event.eventType]) {
            this.editorWrapper.triggerContentChangeEvent();
        }

        if (eventTypesToRefreshFormatState[event.eventType]) {
            this.editorWrapper.triggerRefreshFormatState();
        }

        if (event.eventType == PluginEventType.KeyDown) {
            const key = event.rawEvent.which;
            if (key == Keys.DELETE || key == Keys.BACKSPACE) {
                // Handle image delete content change
                this.editorWrapper.triggerContentChangeEvent();
            }
        }

        if (event.eventType == PluginEventType.BeforePaste) {
            let beforePasteEvent = event;
            let image = beforePasteEvent.clipboardData.image;

            if (image) {
                let imageElement = beforePasteEvent.fragment.firstElementChild;

                const imageUpload = {
                    id: ++this.editorWrapper._imageUploadNextId,
                    lastModified: new Date(image.lastModified).toISOString(),
                    name: image.name,
                    size: image.size,
                    contentType: image.type,
                    element: imageElement,
                };

                // Placeholder
                imageElement.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSJyZ2IoMCwgMTIwLCAyMTIpIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTSAxNC41IDggQSA2LjUgNi41IDAgMCAwIDggMS41IiBzaGFwZS1yZW5kZXJpbmc9Imdlb21ldHJpY1ByZWNpc2lvbiIgLz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9InJnYigxOTksIDIyNCwgMjQ0KSIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0gNy45OTk5OTk5OTk5OTk5OTkgMS41IEEgNi41IDYuNSAwIDEgMCAxNC41IDgiIHNoYXBlLXJlbmRlcmluZz0iZ2VvbWV0cmljUHJlY2lzaW9uIiAvPjwvc3ZnPg==';
                imageElement.className = 'animate-spin h-5 w-5 my-2';
                imageElement.style.maxWidth = '100%';

                beforePasteEvent.sanitizingOption.additionalAllowedAttributes = ['class'];

                this.editorWrapper._imagesById[imageUpload.id] = imageUpload;

                // Attach the blob data itself as a non-enumerable property so it doesn't appear in the JSON.
                Object.defineProperty(imageUpload, 'blob', { value: image });

                this.editorWrapper.onImageUpload(imageUpload);
            }
        }
    }
}

class InternalImagePlugin extends ImageEdit {
    constructor(editorWrapper, options) {
        super(options);
        this.editorWrapper = editorWrapper;
    }

    getName() {
        return 'InternalImagePlugin';
    }

    initialize(editor) {
        super.initialize(editor);
    }

    dispose() {
        this.editorWrapper = null;
        super.dispose();
    }

    setEditingImage(image, operationOrSelect) {

        if (this.editorWrapper
            && this.image
            && this.wasResized) {
            this.editorWrapper.onContentChanged();
        }

        const currentImage = this.image;

        super.setEditingImage(image, operationOrSelect);

        if (currentImage && currentImage.style.minWidth && !this.wasResized) {
            currentImage.style.maxWidth = currentImage.style.minWidth;
        } else if (currentImage && currentImage.style.minWidth && this.wasResized) {
            currentImage.style.minWidth = '';
        }
    }
}

class InternalTablePlugin extends TableResize {
    constructor(editorWrapper, options) {
        super(options);
        this.editorWrapper = editorWrapper;
    }

    getName() {
        return 'InternalTablePlugin';
    }

    initialize(editor) {
        super.initialize(editor);
    }

    dispose() {
        this.editorWrapper = null;
        super.dispose();
    }

    setTableEditor(table) {

        if (this.editorWrapper
            && this.tableEditor
            && table != this.tableEditor.table) {
            this.editorWrapper.triggerContentChangeEvent();
        }

        super.setTableEditor(table);
    }
}

export class RichTextEditor {
    constructor(element, toolbarElement, imageInputElement, callbackRef, initialContent) {
        const options = {
            plugins: [
                new ContentEdit(),
                new HyperLink(url => 'Ctrl+Click to follow the link: ' + url),
                new Paste(),
                new InternalPlugin(this),
                new InternalImagePlugin(this, {
                    preserveRatio: true,
                }),
                new InternalTablePlugin(this),
                new CutPasteListChain(),
                new TableCellSelection(),
                new CustomReplace(),
            ],
            experimentalFeatures: [
                'AdaptiveHandlesResizer',
                'AutoFormatList',
                'ConvertSingleImageBody',
                'ImageCrop',
                'ImageRotate',
                'ListItemAlignment',
                'PasteWithLinkPreview',
                'SingleDirectionResize',
                'TableAlignment',
            ],
            initialContent: initialContent,
        };

        this.element = element;
        this.onElementFocusOut = this.onElementFocusOut.bind(this);
        this.element.addEventListener("focusout", this.onElementFocusOut);

        this.editor = new Editor(this.element, options);

        this.callbackRef = callbackRef;

        this.onContentChanged = this.onContentChanged.bind(this);
        this.onFormatStateChanged = this.onFormatStateChanged.bind(this);

        this.triggerContentChangeEvent = debounce(this.onContentChanged, 100);
        this.triggerRefreshFormatState = debounce(this.onFormatStateChanged, 100);

        this._imageUploadNextId = 0;
        this._imagesById = [];

        this.toolbarElement = toolbarElement;

        if (this.toolbarElement) {
            this.onToolbarMouseDown = this.onToolbarMouseDown.bind(this);
            this.onToolBarFocusOut = this.onToolBarFocusOut.bind(this);

            this.toolbarElement.addEventListener("mousedown", this.onToolbarMouseDown);
            this.toolbarElement.addEventListener("focusout", this.onToolBarFocusOut);
        }

        this.imageInputElement = imageInputElement;
        this.onImageInputElementChange = this.onImageInputElementChange.bind(this);
        this.imageInputElement.addEventListener('change', this.onImageInputElementChange);
    }

    dispose() {
        if (this.editor) {
            this.editor.dispose();
            this.editor = null;
        }

        if (this.callbackRef) {
            this.callbackRef.dispose();
            this.callbackRef = null;
        }

        this.element.removeEventListener("focusout", this.onElementFocusOut);

        if (this.toolbarElement) {
            this.toolbarElement.removeEventListener("mousedown", this.onToolbarMouseDown);
            this.toolbarElement.removeEventListener("focusout", this.onToolBarFocusOut);
        }

        if (this.imageInputElement) {
            this.imageInputElement.removeEventListener('change', this.onImageInputElementChange);
        }

        this._imageUploadNextId = 0;
        this._imagesById = [];
    }

    onContentChanged() {
        if (this.callbackRef) {
            this.callbackRef.invokeMethodAsync("OnTextChanged", this.getContents());
        }
    }

    onFormatStateChanged() {
        if (this.callbackRef) {
            const formatState = getFormatState(this.editor);
            this.callbackRef.invokeMethodAsync("OnFormatStateChanged", formatState);
        }
    }

    onImageUpload(imageUpload) {
        if (this.callbackRef) {
            this.callbackRef.invokeMethodAsync("OnImageUpload", imageUpload);
        }
    }

    onToolbarMouseDown(e) {
        e.preventDefault();
    }

    onToolBarFocusOut(e) {
        if (e.relatedTarget && this.element.parentNode.contains(e.relatedTarget)) {
            e.stopPropagation();
        }
    }

    onElementFocusOut(e) {
        if (e.relatedTarget && this.element.parentNode.contains(e.relatedTarget)) {
            e.stopPropagation();
        }
    }

    onImageInputElementChange() {
        let image = this.imageInputElement.files[0];
        if (image) {
            readFile(image, dataUrl => {
                if (dataUrl && !this.editor.isDisposed()) {
                    this.editor.addUndoSnapshot(() => {
                        const imageElement = this.editor.getDocument().createElement('img');
                        // Placeholder
                        imageElement.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSJyZ2IoMCwgMTIwLCAyMTIpIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTSAxNC41IDggQSA2LjUgNi41IDAgMCAwIDggMS41IiBzaGFwZS1yZW5kZXJpbmc9Imdlb21ldHJpY1ByZWNpc2lvbiIgLz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9InJnYigxOTksIDIyNCwgMjQ0KSIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0gNy45OTk5OTk5OTk5OTk5OTkgMS41IEEgNi41IDYuNSAwIDEgMCAxNC41IDgiIHNoYXBlLXJlbmRlcmluZz0iZ2VvbWV0cmljUHJlY2lzaW9uIiAvPjwvc3ZnPg==';
                        imageElement.style.maxWidth = '100%';
                        imageElement.className = 'animate-spin h-5 w-5 my-2';
                        this.editor.insertNode(imageElement);

                        const imageUpload = {
                            id: ++this._imageUploadNextId,
                            lastModified: new Date(image.lastModified).toISOString(),
                            name: image.name,
                            size: image.size,
                            contentType: image.type,
                            element: imageElement,
                        };

                        this._imagesById[imageUpload.id] = imageUpload;

                        // Attach the blob data itself as a non-enumerable property so it doesn't appear in the JSON.
                        Object.defineProperty(imageUpload, 'blob', { value: image });

                        this.onImageUpload(imageUpload);

                    }, ChangeSource.Format);
                }
            });
        }
    }

    setContents(contents) {
        if (!contents || !contents.htmlText) {
            this.editor.setContent(null);
            return;
        }

        this.editor.setContent(contents.htmlText);
    }

    getContents() {
        let textContents = null;
        let htmlContents = null;

        if (!this.editor.isEmpty()) {
            textContents = this.editor.getContent(GetContentMode.PlainText);
            htmlContents = this.editor.getContent(GetContentMode.CleanHTML);
        }

        return { plainText: textContents, htmlText: htmlContents };
    }

    setFontName(fontName) {
        setFontName(this.editor, fontName);
    }

    setFontSize(fontSize) {
        setFontSize(this.editor, fontSize);
    }

    toggleBold() {
        toggleBold(this.editor);
    }

    toggleItalic() {
        toggleItalic(this.editor);
    }

    toggleUnderline() {
        toggleUnderline(this.editor);
    }

    setBackgroundColour(colour) {
        setBackgroundColor(this.editor, colour);
    }

    setTextColour(colour) {
        setTextColor(this.editor, colour);
    }

    toggleBullet() {
        toggleBullet(this.editor);
    }

    toggleNumbering() {
        toggleNumbering(this.editor);
    }

    toggleStrikeThrough() {
        toggleStrikethrough(this.editor);
    }

    toggleBlockQuote() {
        toggleBlockQuote(this.editor);
    }

    toggleSubscript() {
        toggleSubscript(this.editor);
    }

    toggleSuperscript() {
        toggleSuperscript(this.editor);
    }

    alignLeft() {
        setAlignment(this.editor, Alignment.Left);
        setAlignment(this.editor, Alignment.Left); // Fixes image alignment not setting straight away
    }

    alignCenter() {
        setAlignment(this.editor, Alignment.Center);
        setAlignment(this.editor, Alignment.Center); // Fixes image alignment not setting straight away
    }

    alignRight() {
        setAlignment(this.editor, Alignment.Right);
        setAlignment(this.editor, Alignment.Right); // Fixes image alignment not setting straight away
    }

    createLink(link, altText, displayText) {
        createLink(this.editor, link, altText, displayText);
    }

    removeLink() {
        removeLink(this.editor);
    }

    clearFormat() {
        clearFormat(this.editor, ClearFormatMode.AutoDetect);
    }

    setImageAltText(altText) {
        setImageAltText(this.editor, altText);
    }

    resizeImage(percentage) {
        this.editor.queryElements('img', QueryScope.InSelection, node => {
            this.editor.addUndoSnapshot(() => {
                resetImage(this.editor, node);

                if (percentage > 0 && percentage < 100) {
                    node.style.width = percentage + '%';
                    node.style.minWidth = percentage + '%';
                    node.style.maxWidth = percentage + '%';
                }
            });
        });
    }

    undo() {
        this.editor.undo();
    }

    redo() {
        this.editor.redo();
    }

    toggleHeader(level) {
        toggleHeader(this.editor, level);
        this.toggleBold();
    }

    increaseFontSize() {
        changeFontSize(this.editor, FontSizeChange.Increase);
    }

    decreaseFontSize() {
        changeFontSize(this.editor, FontSizeChange.Decrease);
    }

    increaseIndentation() {
        setIndentation(this.editor, Indentation.Increase);
    }

    decreaseIndentation() {
        setIndentation(this.editor, Indentation.Decrease);
    }

    insertTable(columns, rows, format) {
        insertTable(this.editor, columns, rows, format);
    }

    editTable(tableOperation) {
        editTable(this.editor, tableOperation);
    }

    insertImage() {
        this.imageInputElement.click();
    }

    finishImageUpload(imageId, src) {
        const image = this._imagesById[imageId];
        if (!image) {
            return;
        }

        // Reset image input element
        this.imageInputElement.value = '';

        image.element.src = src;
        image.element.crossorigin = 'Anonymous';
        image.element.removeAttribute("style");
        image.element.removeAttribute("class");
        image.element.style.maxWidth = '100%';

        this._imagesById.splice(imageId, 1);
        this.triggerContentChangeEvent();
    }

    readImageData(imageId) {
        const image = this._imagesById[imageId];
        return image.blob;
    }
}

export function createNewEditor(element, toolbarElement, imageInputElement, callbackRef, initialContent) {
    return new RichTextEditor(element, toolbarElement, imageInputElement, callbackRef, initialContent?.htmlText);
}