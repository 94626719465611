﻿import { findClosestScrollContainer } from '../../Utility/utils';

export class LazyLoader {
    constructor(callbackRef, triggerElement, useViewport, rootMargin) {
        if (!callbackRef || !triggerElement) {
            return;
        }

        this.intersectionCallback = this.intersectionCallback.bind(this);

        this.callbackRef = callbackRef;
        this.triggerElement = triggerElement;

        if (!useViewport) {
            this.scrollContainer = findClosestScrollContainer(this.triggerElement);
        }

        (this.scrollContainer || document.documentElement).style.overflowAnchor = 'none';

        this.intersectionObserver = new IntersectionObserver(this.intersectionCallback, {
            root: this.scrollContainer,
            rootMargin: `${rootMargin}px`,
        });

        this.intersectionObserver.observe(this.triggerElement);

        this.mutationObserver = this.createMutationObserver();
    }

    dispose() {
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect();
        }

        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
        }

        this.callbackRef.dispose();

        this.callbackRef = null;
        this.intersectionObserver = null;
        this.mutationObserver = null;
    }

    createMutationObserver() {
        // Without the use of thresholds, IntersectionObserver only detects binary changes in visibility,
        // so if an element gets resized but remains visible, no additional callbacks will occur. By unobserving
        // and reobserving spacers when they get resized, the intersection callback will re-run if they remain visible.
        const mutationObserver = new MutationObserver(() => {
            this.intersectionObserver.unobserve(this.triggerElement);
            this.intersectionObserver.observe(this.triggerElement);
        });

        mutationObserver.observe(this.triggerElement, { attributes: true });

        return mutationObserver;
    }

    intersectionCallback(entries) {
        entries.forEach((entry) => {
            if (!entry.isIntersecting) {
                return;
            }

            if (entry.target === this.triggerElement) {
                this.callbackRef.invokeMethodAsync('OnTriggerVisible');
            }
        });
    }

    scrollToBottomOfContainer() {
        if (!this.scrollContainer) {
            return;
        }

        this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
    }
}

export function initLazyLoader(callbackRef, triggerElement, useViewport = false, rootMargin = 50) {
    return new LazyLoader(callbackRef, triggerElement, useViewport, rootMargin)
}