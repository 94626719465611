﻿export function setProfile(tenantId, userId, email, name) {
    const data = {
        uid: userId,
        tenantId: tenantId,
        email: email,
        name: name,
    };

    window.InlineManual("boot", data);
}

export function updateState() {
    window.InlineManual("update");
}