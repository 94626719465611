﻿import {
    computePosition,
    autoUpdate,
    flip,
    shift,
    offset,
    hide
} from '@floating-ui/dom';

export function createPopover(anchor, content, offsetPos = 5, padding = 5, placement = 'bottom', strategy = 'absolute', autoHide = true, doAutoUpdate = true) {
    const computeFunc = () => {
        computePosition(anchor, content, {
            placement: placement,
            strategy: strategy,
            middleware: [offset(offsetPos), flip(), shift({ padding: padding }), hide()],
        }).then(({ x, y, middlewareData }) => {
            const { referenceHidden } = middlewareData.hide;
            Object.assign(content.style, {
                left: `${x}px`,
                top: `${y}px`,
                visibility: referenceHidden && autoHide ? 'hidden' : 'visible',
            });
        });
    };

    if (doAutoUpdate) {
        return { dispose: autoUpdate(anchor, content, computeFunc) };
    }
    else {
        return { dispose: () => {} };
    }
}